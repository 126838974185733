import { Card } from '@/features/app';
import { Language, useI18nContext } from '@/features/i18n';

export function GcdmseClearance({
  documentUrl,
}: {
  documentUrl: string;
}) {
  const { language } = useI18nContext();

  return (
    <Card>
      <div className="text-center">
        {{
          [Language.ENGLISH]: 'Result 2 of 2',
          [Language.SPANISH]: 'Resultado 2 de 2',
        }[language]}
      </div>
      <div className="text-lg font-semibold text-center mb-2">
        {{
          [Language.ENGLISH]: 'Ground Cannabis Dust Allergy Screening',
          [Language.SPANISH]: 'Chequeo de Alergia al Polvo de Cannabis Molido',
        }[language]}
      </div>
      <h2 className="text-3xl font-semibold text-center text-green-600 mb-8">
        {{
          [Language.ENGLISH]: 'CLEARED',
          [Language.SPANISH]: 'AUTORIZADO',
        }[language]}
      </h2>
      <p className="mb-4">
        {{
          [Language.ENGLISH]: `
            Your annual screening is now complete. No further action is
            required. If you experience any symptoms you think may be related to
            your work, or if you have concerns regarding the result of this
            screening, please notify your supervisor immediately. If you have
            questions, please contact
          `,
          [Language.SPANISH]: `
            Su chequeo anual ha finalizado. No se requiere ninguna otra acción.
            Si experimenta algún síntoma que cree que puede estar relacionado
            con su trabajo, o si tiene alguna inquietud sobre el resultado de
            este chequeo, notifíquelo inmediatamente a su supervisor. Si tiene
            alguna pregunta, póngase en contacto escribiendo a
          `,
        }[language]}
        <a className="text-primary hover:underline" href="mailto:support@proximawork.com">support@proximawork.com</a>
        {{
          [Language.ENGLISH]: '.',
          [Language.SPANISH]: '.',
        }[language]}
      </p>
      <div className="border-t-2 border-gray-100 pt-4 mt-4">
        {{
          [Language.ENGLISH]: `
            A clearance document will be sent to the email address you provided
            on this form. Click below to download a printable copy of your
            clearance document now:
          `,
          [Language.SPANISH]: `
            Se le enviará un documento de visto bueno a la dirección de correo
            electrónico que haya facilitado en este formulario. Haga clic a
            continuación para descargar una copia imprimible de su documento de
            visto bueno:
          `,
        }[language]}
      </div>
      <div className="mt-4">
        <a href={documentUrl} rel="noreferrer" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 text-primary mx-auto">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m.75 12 3 3m0 0 3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
          </svg>
        </a>
      </div>
    </Card>
  );
}
